<template>
  <div>
    <div class="block box-shadow edit_field mrb20">
      <div class="flex align-center mrb20">
        <div class="size16">时间选择：</div>
        <!-- <el-date-picker v-model="dayValue" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime(dayValue)" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker> -->

        <el-date-picker v-model="dayValue" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" @change="getTime" range-separator="~" start-placeholder="订单开始时间" end-placeholder="订单结束时间">
        </el-date-picker>
        <div class="mrl20">
          <el-button type="primary" icon="el-icon-search" @click="getList(from)">搜索</el-button>
        </div>
      </div>
      <div class="mrt20 mrb20 flex align-center justify-around  " v-if="dataList.order">

        <div class="size24  flexcc flex-column">
          <div class="mrb10">{{ dataList.order.status0 }}</div>
          <div>待付款</div>
        </div>
        <div class="size24 flexcc flex-column">
          <div class="mrb10">{{ dataList.order.status1 }}</div>
          <div>待发货</div>
        </div>
        <div class="size24 flexcc flex-column">
          <div class="mrb10">{{ dataList.order.status2 }}</div>
          <div>待签收</div>
        </div>
        <div class="size24 flexcc flex-column">
          <div class="mrb10">{{ dataList.order.status3 }}</div>
          <div>退款/售后</div>
        </div>
      </div>
    </div>

    <div class="edit_field mrb60 box-shadow  edit_field ">
      <div class="flex  mrb20 flex align-center">
        <div class="mrr5 size18">实时数据</div>
        <div class="size14">更新时间: {{ dataList.updated_at }}</div>
      </div>
      <div class="flex align-center flex-wrap">
        <div class="size20 mrb20 flexcc flex-column" style="width: 30%;">
          <div>支付金额</div>
          <div class="mrb10">{{ dataList.order_pay_amount }}</div>
        </div>
        <div class="size20 mrb20   flexcc flex-column" style="width: 30%;">
          <div>支付订单数</div>
          <div class="mrb10">{{  dataList.order_count }}</div>
        </div>
        <div class="size20 mrb20  flexcc flex-column" style="width: 30%;">
          <div>支付买家数</div>
          <div class="mrb10">{{  dataList.pay_user_count }}</div>
        </div>
        <div class="size20 mrb20   flexcc flex-column" style="width: 30%;">
          <div>访问次数</div>
          <div class="mrb10">{{  dataList.shop_view_num }}</div>
        </div>
        <div class="size20  mrb20  flexcc flex-column" style="width: 30%;">
          <div>访问人数</div>
          <div class="mrb10">{{  dataList.shop_view_count }}</div>
        </div>
        <div class="size20  mrb20  flexcc flex-column" style="width: 30%;">
          <div>商品浏览量</div>
          <div class="mrb10">{{  dataList.shop_shop_count }}</div>
        </div>
      </div>
    </div>

    <div class="edit_field  box-shadow ">
      <div class="mrb20 size18">商品管理</div>
      <div class="flex flex-between">
        <div class="flex " v-if="dataList.goods">
          <div class="size18 mrr40 flexcc flex-column align-center">
            <div class="mrb10">{{ dataList.goods.status1 }}</div>
            <div>在售中</div>
          </div>
          <div class="size18 mrr40 flexcc flex-column align-center">
            <div class="mrb10">{{ dataList.goods.status2 }}</div>
            <div>已售罄</div>
          </div>
          <div class="size18 mrr40 flexcc flex-column align-center">
            <div class="mrb10">{{ dataList.goods.status0 }}</div>
            <div>已下架</div>
          </div>
        </div>
        <div class="add_box flexcc pointer " @click="addgoods(dataList)">
          发布新商品
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dayValue: "",
      dataList: {},
      from: {
        //查询条件
        start_date: "",
        end_date: "",
      },
    };
  },

  created() {
    this.getTestTime(); //先调用默认时间
    this.getList(this.from); //在调页面查询  此方法省略
  },
  mounted() {},
  computed: {},

  methods: {
    async getList(row) {
      const { data } = await this.$Api.shopIndex(row);
      this.dataList = data;
    },
    addgoods(row) {
      // console.log("row.industry_id", row.industry_id);
      switch (row.industry_id) {
        case 5:
          this.$router.push({
            path: "/sport/addedit",
          });
          break;
        case 6:
          this.$router.push({
            path: "/shop/commodity/addcommodity",
          });
          break;
      }
    },
    // 默认 当日
    getTestTime() {
      let isDate = new Date();
      let sTime = `${isDate.getFullYear()}-${
        isDate.getMonth() + 1
      }-${isDate.getDate()}`;
      let eTime = `${isDate.getFullYear()}-${
        isDate.getMonth() + 1
      }-${isDate.getDate()}`;

      sTime = `${sTime} 00:00:00`;
      eTime = `${eTime} 23:59:59`;
      this.dayValue = [new Date(sTime), new Date(eTime)]; // 显示的默认时间
      this.from.start_date = sTime; //查询 赋值
      this.from.end_date = eTime;
    },
    getTime(e) {
      if (e) {
        this.from.start_date = e[0];
        this.from.end_date = e[1];
      } else {
        this.from.start_date = "";
        this.from.end_date = "";
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  padding: 30px 20px;
  background-color: #ffffff;
}
.add_box {
  border: 1px solid black;
  width: 100px;
  height: 100px;
}
</style>